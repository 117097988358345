import React from "react";
import MMInput from "../common/mmInput/MMInput";
import { signInPage } from "../../assets/data/data.json";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { login } from "../../store/slices/authSlice";

import {
  INITIAL_VALUES_LOGIN,
  FORM_VALIDATION_LOGIN,
} from "../../utils/validations";

export default function LoginForm() {
  const dispatch = useDispatch();

  const onSubmitForm = (values) => {
    dispatch(login(values));
  };

  return (
    <>
      <div className="login login-form-wrapper">
        <div className="heading-wrapper">
          <h1> {signInPage.title} </h1>
        </div>
        <Formik
          initialValues={INITIAL_VALUES_LOGIN}
          validationSchema={FORM_VALIDATION_LOGIN}
          onSubmit={onSubmitForm}
        >
          <Form>
            <div className="login-form">
              <MMInput
                name="emailSignIn"
                type="email"
                autoComplete="off"
                placeholder="Email"
              />
              <MMInput
                name="passSignIn"
                type="password"
                autoComplete="off"
                placeholder="Password"
              />
            </div>
            <div className="login-actions">
              <div className="login-actions-top">
                <div className="remember-wrapper">
                  <label htmlFor="remember">
                    <input type="checkbox" id="remember" />
                    <span className="rememberMe">{signInPage.remember}</span>
                    <span className="checkmark"></span>
                  </label>
                </div>
                <Link to="/reset-password">{signInPage.linkToForgetPass}</Link>
              </div>
              <div className="login-actions-bottom">
                <div className="simple-btn-wrapper">
                  <button type="submit"> {signInPage.title} </button>
                </div>

                <div className="other-wrapper">
                  <p>{signInPage.otherText}</p>
                  <Link to="/sign-up"> {signInPage.linkToSignUp} </Link>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}
