import axios from "axios";

const api = axios.create({
  baseURL: "https://1125.freelancedeveloper.site/api",
  headers: {
    "Content-Type": "application/json",
  },
});

const auth = JSON.parse(localStorage.getItem("persist:auth"));
if (auth) {
  const token = JSON.parse(auth.token);
  api.defaults.headers.Authorization = `Bearer ${token}`;
}
// const requestHandler = (request) => {
//   const auth = JSON.parse(localStorage.getItem("persist:auth"));
//   const token = JSON.parse(auth.token);
//   console.log(localStorage.getItem("persist:auth"));
//   request.headers.Authorization = `Bearer ${token}`;

//   return request;
// };

// const responseHandler = (response) => {
//   if (response.status_code === 401) {
//     window.location = "/sign-in";
//   }

//   return response;
// };

// api.interceptors.request.use((request) => requestHandler(request));

// api.interceptors.response.use((response) => responseHandler(response));

export default api;
