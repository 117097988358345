import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../axiosConfig";
import { changeUserVotedStatus } from "./authSlice";

const initialState = {
  data: [],
  loading: false,
  rowIds: [],
};

export const addVote = createAsyncThunk(
  "vote/add",
  async (obj, { dispatch }) => {
    const response = await api.post(`/add-rate`, obj);
    if (response.data.status_code === 200) {
      await dispatch(changeUserVotedStatus());
      await dispatch(getVotes());
    }
    return response.data;
  }
);

export const editVotes = createAsyncThunk(
  "vote/edit",
  async (obj, { dispatch }) => {
    const response = await api.post(`/edit-info`, obj);
    if (response.data.status_code === 200) {
      dispatch(getVotes);
    }
    return response.data;
  }
);

export const getVotes = createAsyncThunk("vote/getVotes", async () => {
  const response = await api.get(`/show-info`);
  return response.data;
});

export const voteSlice = createSlice({
  name: "vote",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get Votes //
      .addCase("vote/getVotes/pending", (state) => {
        state.loading = true;
      })
      .addCase("vote/getVotes/fulfilled", (state, { payload }) => {
        state.loading = false;

        const data = payload.ShowInfo;
        let result = [];
        let rowIds = [];
        for (let i = 0; i < data.length; i++) {
          if (i % 4 === 0) {
            result.push(data.slice(i, i + 4));
          }
          rowIds.push(data[i].id);
        }

        state.data = result;
        state.rowIds = rowIds;
      })
      .addCase("vote/getVotes/rejected", (state, { payload }) => {
        state.loading = false;
      })

      // Edit Votes //
      .addCase("vote/edit/pending", (state) => {
        state.loading = true;
      })
      .addCase("vote/edit/fulfilled", (state, { payload }) => {
        state.loading = false;
      })
      .addCase("vote/edit/rejected", (state, { payload }) => {
        state.loading = false;
      })

      // Add Vote //
      .addCase("vote/add/pending", (state) => {
        state.loading = true;
      })
      .addCase("vote/add/fulfilled", (state, { payload }) => {
        state.loading = false;
      })
      .addCase("vote/add/rejected", (state, { payload }) => {
        state.loading = false;
      });
  },
});

// export const {  } = voteSlice.actions;

export default voteSlice.reducer;
