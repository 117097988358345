import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import storage from "redux-persist/lib/storage";
import noteReducer from "./slices/noteSlice";
import voteReducer from "./slices/voteSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const authPersistConfig = {
  key: "auth",
  version: 1,
  storage: storage,
  blacklist: ["note"],
  // whitelist: ['auth']
  // blacklist: ["auth"], //not persisted reducer
};

const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    note: noteReducer,
    vote: voteReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistor = persistStore(store);

export { store, persistor };
