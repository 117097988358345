import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import {
  SignIn,
  SignUp,
  Profile,
  ResetPassword,
  SetNewPassword,
  CreateVoting,
  Voting,
  NotFound,
} from "./pages";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { Route } from "react-router-dom";
import "./App.scss";
import { useSelector } from "react-redux";

import { ToastProvider } from "react-toast-notifications";

export default function App() {
  const auth = useSelector((state) => state.auth);

  return (
    <div className="App">
      <ToastProvider>
        <BrowserRouter>
          <Switch>
            <PublicRoute
              path="/"
              component={SignIn}
              restricted={auth && auth.isLogin}
              exact
            />
            <PublicRoute
              path="/sign-in"
              component={SignIn}
              restricted={auth && auth.isLogin}
            />
            <PublicRoute
              path="/sign-up"
              component={SignUp}
              restricted={auth && auth.isLogin}
            />
            <PublicRoute
              path="/reset-password"
              component={ResetPassword}
              restricted={auth && auth.isLogin}
            />
            <PublicRoute
              path="/add-new-pass/:token"
              component={SetNewPassword}
              restricted={auth && auth.isLogin}
            />
            <PrivateRoute path="/profile" component={Profile} />

            {auth && auth.user && auth.user.is_admin === "admin" && (
              <PrivateRoute path="/create-voting" component={CreateVoting} />
            )}
            <PrivateRoute path="/voting" component={Voting} />

            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </ToastProvider>
    </div>
  );
}
