import React, { useEffect, useRef } from "react";
import "./MMSelect.scss";
import { useField } from "formik";
import { useFormikContext } from "formik";
import { useToggle } from "../../../hooks/useToggle";
import MMVectorIcon from "../../common/mmVectorIcon/MMVectorIcon";

const MMSelect = ({ name, initialVal, options, ...otherProps }) => {
  const dropdownRef = useRef();
  const [open, toggle] = useToggle(false, dropdownRef);
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const configsSelect = {
    ...field,
    ...otherProps,
    onClick: toggle,
  };

  useEffect(() => {
    initialVal && setFieldValue(name, initialVal);
  }, [initialVal, name, setFieldValue]);
  return (
    <div className="input-wrapper select-wrapper">
      <input {...configsSelect} readOnly />

      <div ref={dropdownRef}>
        <div className="select-dropdown">
          {open &&
            Object.keys(options).map((item, index) => {
              return (
                <div
                  key={index}
                  className="select-dropdown-option"
                  onClick={() => setFieldValue(name, options[item])}
                >
                  {options[item]}
                </div>
              );
            })}
        </div>
      </div>
      <MMVectorIcon name="arrowDown" />
    </div>
  );
};

export default React.memo(MMSelect);
