import React from "react";
import { LayoutOther, CreateVotingForm } from "../../components";
import { createVotePage } from "../../assets/data/data.json";
import "./CreateVoting.scss";
export default function CreateVoting() {
  return (
    <LayoutOther>
      <div className="create-voting-container">
        <div className="heading-wrapper">
          <h1>{createVotePage.title}</h1>
        </div>
        <CreateVotingForm />
      </div>
    </LayoutOther>
  );
}
