import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "",
  message: "",
};

export const noteSlice = createSlice({
  name: "note",
  initialState,
  reducers: {
    addNote(state, { payload }) {
      state.type = payload.type;
      state.message = payload.message;
    },
    cleanNote(state) {
      state.type = "";
      state.message = "";
    },
  },
});

export const { addNote, cleanNote } = noteSlice.actions;

export default noteSlice.reducer;
