import React, { useEffect, useState, useCallback } from "react";
import "./CreateVotingForm.scss";
import { Formik, Form } from "formik";
import { createVotePage } from "../../assets/data/data.json";

import MMInput from "../common/mmInput/MMInput";
import MMTextArea from "../common/mmTextarea/MMTextArea";
import MMVectorIcon from "../common/mmVectorIcon/MMVectorIcon";
import MMInputFile from "../common/mmInputFile/MMInputFile";
import { useSelector, useDispatch } from "react-redux";
import { editVotes, getVotes } from "../../store/slices/voteSlice";

const CreateVotingForm = () => {
  const [previewSrc, setPreviewSrc] = useState({});
  const [altPreviewSrc, setAltPreviewSrc] = useState({});
  const dispatch = useDispatch();
  const vote = useSelector((state) => state.vote);

  useEffect(() => {
    dispatch(getVotes());
  }, [dispatch]);

  const submitVoteForm = (values) => {
    const obj = {};
    const arrIds = vote.rowIds;
    console.log(vote.rowIds);
    console.log(altPreviewSrc);
    for (let i = 0; i < arrIds.length; i++) {
      obj[arrIds[i]] = [
        {
          name: values[`nameVotePers${arrIds[i]}`] || "",
          profession: values[`proffVotePers${arrIds[i]}`] || "",
          description: values[`descVotePers${arrIds[i]}`] || "",
          img: previewSrc[arrIds[i]],
          id: arrIds[i],
          src: altPreviewSrc[arrIds[i]],
        },
      ];
    }
    dispatch(editVotes(obj));
  };
  const handleChangeAltImages = useCallback((e, columnId) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      if (columnId) {
        setAltPreviewSrc((prev) => {
          if (prev.hasOwnProperty(columnId)) {
            prev[columnId] = reader.result;
          }
          return {
            ...prev,
            [columnId]: reader.result,
          };
        });
      } else {
        setAltPreviewSrc(reader.result);
      }
    };
    if (e.currentTarget.files[0]) {
      reader.readAsDataURL(e.currentTarget.files[0]);
    }
  }, []);
  // console.log(previewSrc, "for Main AVATAR");
  // console.log(altPreviewSrc, "for ALT IMAGES");
  return (
    <Formik initialValues={{}} onSubmit={submitVoteForm}>
      <Form>
        <div className="vote-pers-form">
          {vote.data.map((row, index) => (
            <div className="vote-pers-form-row" key={index}>
              {row.map((item) => (
                <div key={item.id}>
                  <div className="vote-pers-avatar">
                    {item.img && (
                      <img
                        src={
                          previewSrc.hasOwnProperty(item.id)
                            ? previewSrc[item.id]
                            : item.img
                        }
                        alt="avatar"
                      />
                    )}
                    <label
                      htmlFor={`avatarVotePers${item.id}`}
                      className="vote-pers-avatar-label"
                    >
                      <MMVectorIcon name="camera" big />
                      <MMInputFile
                        name={`avatarVotePers${item.id}`}
                        changePreviewSrc={setPreviewSrc}
                        columnId={item.id}
                      />
                    </label>
                  </div>
                  <div className="vote-pers-avatar-alt-images">
                    {item.images.map((x) => (
                      <div key={x.id}>
                        <img alt="PersonAltImage" src={x.src} />
                      </div>
                    ))}
                    {altPreviewSrc.hasOwnProperty(item.id) ? (
                      <div>
                        <img
                          alt="PersonAltImage"
                          src={altPreviewSrc[item.id]}
                        />
                      </div>
                    ) : null}
                    <div className="alt-images-add">
                      <label htmlFor={`avatarVotePersAltImg${item.id}`}>
                        <MMVectorIcon name="plus" />
                        <input
                          type="file"
                          id={`avatarVotePersAltImg${item.id}`}
                          onChange={(e) => handleChangeAltImages(e, item.id)}
                          style={{ display: "none" }}
                        />
                      </label>
                    </div>
                  </div>
                  <div>
                    <MMInput
                      name={`nameVotePers${item.id}`}
                      label="Name"
                      initialVal={item.name}
                    />
                    <MMInput
                      name={`proffVotePers${item.id}`}
                      label="Profession"
                      initialVal={item.profession}
                    />
                    <MMTextArea
                      name={`descVotePers${item.id}`}
                      label="About"
                      initialVal={item.description}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="simple-btn-wrapper">
          <button type="submit"> {createVotePage.btnSubmit} </button>
        </div>
      </Form>
    </Formik>
  );
};

export default React.memo(CreateVotingForm);
