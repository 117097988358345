import React, { useEffect } from "react";
import "./LayoutOther.scss";
import Header from "../header/Header";
import MMLoading from "../common/mmLoading/MMLoading";
import { getUserData } from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import MMNote from "../common/mmNote/MMNote";

export default function LayoutOther({ children }) {
  const store = useSelector((state) => state);
  const auth = store.auth;
  const note = store.note;
  const vote = store.vote;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.user) {
      dispatch(getUserData());
    }
  }, [dispatch, auth.user]);

  return (
    <div id="layout-other">
      <Header />
      <main>
        {children}
        {note && note.message && (
          <MMNote message={note.message} type={note.type} />
        )}
        {(auth.loading || vote.loading) && <MMLoading />}
      </main>
    </div>
  );
}
