import React, { useEffect, useState, useMemo, useRef } from "react";
import { LayoutOther } from "../../components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Voting.scss";
import { votingPage } from "../../assets/data/data.json";
import { useSelector, useDispatch } from "react-redux";
import { getVotes, addVote } from "../../store/slices/voteSlice";
import Slider from "react-slick";
import MMVectorIcon from "../../components/common/mmVectorIcon/MMVectorIcon";
import MMModal from "../../components/common/mmModal/MMModal";
import { useToggle } from "../../hooks/useToggle";

export default function Voting() {
  const store = useSelector((state) => state);
  const vote = store.vote;
  const auth = store.auth.user;
  const dispatch = useDispatch();
  const [voted, setVoted] = useState();

  const modalRef = useRef();
  const [isModal, toggle] = useToggle(false, modalRef);

  useEffect(() => {
    dispatch(getVotes());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (voted) {
      await dispatch(addVote({ id: voted }));
      await toggle();
    }
  };

  const SampleNextArrow = ({ className, style, onClick }) => {
    return (
      <div
        className={className}
        // style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      >
        <MMVectorIcon name="arrowRight" />
      </div>
    );
  };

  const SamplePrevArrow = ({ className, style, onClick }) => {
    return (
      <div
        className={className}
        // style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      >
        <MMVectorIcon name="arrowLeft" />
      </div>
    );
  };

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    }),
    []
  );

  return (
    <LayoutOther>
      <div className="voting-container">
        <div className="heading-wrapper">
          <h1>{votingPage.title}</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="voting-content">
            {vote.data &&
              vote.data.map((row, index) => (
                <div className="voting-content-row" key={index}>
                  {row.map((item) => (
                    <div className="person-wrapper" key={item.id}>
                      <div className="person-avatar">
                        <Slider {...sliderSettings}>
                          <div>
                            <img src={item.img} alt="personPic" />
                          </div>

                          {item.images.map((x) => (
                            <div key={x.id}>
                              <img src={x.src} alt="personPic" />
                            </div>
                          ))}
                        </Slider>
                        {/* <img src={item.img} alt="personPic" /> */}
                        {auth && auth.is_admin !== "admin" && !auth.is_voted && (
                          <div className="person-vote-wrapper">
                            <label htmlFor={`vote${item.id}`}>
                              <input
                                type="radio"
                                id={`vote${item.id}`}
                                name="vote"
                                onChange={() => setVoted(item.id)}
                              />
                              <span className="customRadio"></span>
                            </label>
                          </div>
                        )}
                      </div>
                      <div className="person-info">
                        <h5 className="person-info-name">{item.name}</h5>
                        <p className="person-info-proff">{item.profession}</p>
                        <p className="person-info-desc">{item.description}</p>
                        <p className="person-info-rate">{item.rate}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
          {auth && auth.is_admin !== "admin" && !auth.is_voted && (
            <div className="simple-btn-wrapper">
              <button type="submit"> {votingPage.btnSubmit} </button>
            </div>
          )}
        </form>
      </div>
      {isModal && (
        <MMModal>
          <div ref={modalRef}>
            <div className="heading-wrapper">
              <h1> Thanks for your Vote ...! </h1>
            </div>
            <div className="simple-btn-wrapper">
              <button onClick={toggle}> Close </button>
            </div>
          </div>
        </MMModal>
      )}
    </LayoutOther>
  );
}
