import React from "react";
import "./Layout.scss";
// import Particles from "react-particles-js";
import MMLoading from "../common/mmLoading/MMLoading";
import { useSelector } from "react-redux";
import MMNote from "../common/mmNote/MMNote";

export default function Layout({ children }) {
  const state = useSelector((state) => state);

  return (
    <div id="layout">
      {/* <Particles
        params={{
          particles: {
            number: {
              value: 100,
            },
            size: {
              value: 2,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      /> */}
      <div className="layout-center-content">
        {children}

        {state.note && state.note.message && (
          <MMNote message={state.note.message} type={state.note.type} />
        )}
      </div>
      {state.auth.loading && <MMLoading />}
    </div>
  );
}
