import React, { useState, Suspense } from "react";
import "./EditProfileForm.scss";
import { profilePage } from "../../assets/data/data.json";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  INITIAL_VALUES_PROFILE_EDIT,
  FORM_VALIDATION_PROFILE_EDIT,
} from "../../utils/validations";
import MMInput from "../common/mmInput/MMInput";
import MMSelect from "../common/mmSelect/MMSelect";
import MMTextArea from "../common/mmTextarea/MMTextArea";
import MMNote from "../common/mmNote/MMNote";
import MMVectorIcon from "../../components/common/mmVectorIcon/MMVectorIcon";
import MMInputFile from "../../components/common/mmInputFile/MMInputFile";
import { editUserData, deleteAcc } from "../../store/slices/authSlice";

// import srcAvatar from "../../assets/images/avatar.png";

const EditProfileForm = () => {
  const [previewSrc, setPreviewSrc] = useState("");
  const auth = useSelector((state) => state.auth);
  const userData = auth.user;

  const dispatch = useDispatch();

  const submitEditForm = (values) => {
    const x = {
      name: values.nameEdit,
      date: values.birthEdit,
      interested_category: values.intCategoryEdit,
      sex: values.sexEdit,
      ethnicity: values.ethnicityEdit,
      nationality: values.nationalityEdit,
      about: values.aboutEdit,
      career: values.careerEdit,
      profession: values.proffEdit,
      img: previewSrc,
    };
    dispatch(editUserData(x));
  };
  const deleteAccHandler = () => {
    dispatch(deleteAcc(userData.id));
  };

  return (
    <Suspense fallback="loading...">
      <Formik
        initialValues={INITIAL_VALUES_PROFILE_EDIT}
        validationSchema={FORM_VALIDATION_PROFILE_EDIT}
        onSubmit={submitEditForm}
      >
        {userData && (
          <Form className="edit-profile-form-wrapper">
            <div className="main-content-left">
              <div className="avatar-wrapper">
                <img
                  src={previewSrc !== "" ? previewSrc : userData.img}
                  alt="avatar"
                />

                <label htmlFor="avatarEdit" className="avatar-label">
                  <MMVectorIcon name="camera" big />
                  <MMInputFile
                    name="avatarEdit"
                    changePreviewSrc={setPreviewSrc}
                  />
                </label>
              </div>
              <div className="desc-wrapper">
                {userData && (
                  <>
                    <h3>{userData.name}</h3>
                    <h4>{userData.profession}</h4>
                    <p> {userData.about}</p>
                  </>
                )}
              </div>
            </div>
            <div className="main-content-right">
              <h1>{profilePage.title}</h1>

              <div className="edit-profile-form">
                <div>
                  <MMInput
                    name="nameEdit"
                    type="text"
                    initialVal={userData.name}
                  />
                  <MMInput
                    name="birthEdit"
                    type="date"
                    initialVal={userData.date}
                  />
                  <MMSelect
                    name="sexEdit"
                    type="text"
                    options={{ a: "Male", b: "Female", c: "Prefer not to say" }}
                    placeholder="Sex"
                    initialVal={userData.sex}
                  />
                  <MMInput
                    name="ethnicityEdit"
                    type="text"
                    initialVal={userData.ethnicity}
                    placeholder="Ethnicity"
                  />
                  <MMInput
                    name="nationalityEdit"
                    type="text"
                    initialVal={userData.nationality}
                    placeholder="Nationality"
                  />
                  <MMInput
                    name="emailEdit"
                    type="email"
                    initialVal={userData.email}
                    readOnly
                  />
                </div>
                <div>
                  <MMSelect
                    name="proffEdit"
                    type="text"
                    options={{
                      a: "Producer",
                      b: "Artist",
                      c: "Fashion Designer",
                      d: "Violinist",
                      e: "Pianists",
                      f: "Videographer",
                      g: "Model",
                      h: "Graphic Designer",
                      i: "Photographer",
                    }}
                    placeholder="Profesion"
                    initialVal={userData.profession}
                  />
                  <MMSelect
                    name="intCategoryEdit"
                    type="text"
                    placeholder="Interested Category"
                    options={{
                      a: "Animation & Digital Media",
                      b: "Dance",
                      c: "Choreography",
                      d: "Design & Architecture",
                      e: "Pianists",
                      f: "Fashion & Textiles",
                      g: "Film",
                      h: "Graphic Design",
                      i: "Music",
                      j: "Theatre",
                      k: "Visual arts",
                      l: "Creative direction",
                    }}
                    initialVal={userData.interested_category}
                  />
                  <div className="desc-area">
                    <div>
                      <p> {profilePage.fieldSubtitles[0]} </p>
                      <MMTextArea
                        name="aboutEdit"
                        initialVal={userData.about}
                      />
                    </div>
                    <div>
                      <p> {profilePage.fieldSubtitles[1]} </p>
                      <MMTextArea
                        name="careerEdit"
                        initialVal={userData.career}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="simple-btn-wrapper">
                <button type="submit"> {profilePage.btnSubmit} </button>
              </div>
              {userData.is_admin !== "admin" && (
                <div className="delete-account-wrapper">
                  <p onClick={deleteAccHandler}>{profilePage.deleteAcc}</p>
                </div>
              )}

              {auth && auth.note && <MMNote message={auth.note.success} suc />}
            </div>
          </Form>
        )}
      </Formik>
    </Suspense>
  );
};

export default React.memo(EditProfileForm);
