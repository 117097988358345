import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { cleanNote } from "../../../store/slices/noteSlice";
// import { useToasts } from "react-toast-notifications";

const MMNote = ({ message = "Test Text", type = "error" }) => {
  const dispatch = useDispatch();
  // const { addToast } = useToasts();

  useEffect(() => {
    // addToast(message, {
    //   appearance: type,
    //   autoDismiss: true,
    // });

    const timeout = setTimeout(() => {
      dispatch(cleanNote());
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch]);
  return <p className={`${type}`}>{message}</p>;
};
export default React.memo(MMNote);
