import React from "react";
import { Layout, LoginForm } from "../../components";

export default function SignIn() {
  return (
    <Layout>
      <LoginForm />
    </Layout>
  );
}
