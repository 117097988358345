import React from "react";
import { setNewPasswordPage } from "../../assets/data/data.json";
import {
  INITIAL_VALUES_SETNEWPASS,
  FORM_VALIDATION_SETNEWPASS,
} from "../../utils/validations";

import { Formik, Form } from "formik";
import MMInput from "../common/mmInput/MMInput";
import { useSelector, useDispatch } from "react-redux";
import { setNewPass } from "../../store/slices/authSlice";
import { useHistory, useParams } from "react-router-dom";
export default function SetNewPasswordForm() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const params = useParams();
  const token = params.token;
  const history = useHistory();
  const onSubmitForm = (values, { resetForm }) => {
    dispatch(
      setNewPass({
        ...values,
        token: token,
      })
    ).then(({ payload }) => {
      if (payload.status_code === 200) {
        resetForm();
        history.push("/sign-in");
      }
    });
  };

  return (
    <>
      <div className="login login-form-wrapper">
        <div className="heading-wrapper">
          <h1> {setNewPasswordPage.title} </h1>
        </div>
        <Formik
          initialValues={INITIAL_VALUES_SETNEWPASS}
          validationSchema={FORM_VALIDATION_SETNEWPASS}
          onSubmit={onSubmitForm}
        >
          <Form>
            <div className="login-form">
              <MMInput
                name="emailSet"
                type="email"
                autoComplete="off"
                placeholder="Email"
              />
              <MMInput
                name="newPassSet"
                type="password"
                autoComplete="off"
                placeholder="New Password"
              />
              <MMInput
                name="confNewPassSet"
                type="password"
                autoComplete="off"
                placeholder="Confirm New Password"
              />
            </div>
            <div className="login-actions">
              <div className="login-actions-bottom">
                <div className="simple-btn-wrapper">
                  <button type="submit">{setNewPasswordPage.submitBtn}</button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
        {auth && auth.errors && auth.errors.message && (
          <p className="err"> {auth.errors.message} </p>
        )}
      </div>
    </>
  );
}
