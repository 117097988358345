import * as Yup from "yup";

// Login //
export const INITIAL_VALUES_LOGIN = {
  emailSignIn: "",
  passSignIn: "",
};

export const FORM_VALIDATION_LOGIN = Yup.object({
  emailSignIn: Yup.string().email("Invalid email format").required("Required!"),
  passSignIn: Yup.string().min(8, "Minimum 8 characters").required("Required!"),
});
// Reset //
export const INITIAL_VALUES_RESET = {
  emailReset: "",
};

export const FORM_VALIDATION_RESET = Yup.object({
  emailReset: Yup.string().email("Invalid email format").required("Required!"),
});
// Set New Password //
export const INITIAL_VALUES_SETNEWPASS = {
  emailSet: "",
  newPassSet: "",
  confNewPassSet: "",
};

export const FORM_VALIDATION_SETNEWPASS = Yup.object({
  emailSet: Yup.string().email("Invalid email format").required("Required!"),
  newPassSet: Yup.string().min(8, "Minimum 8 characters").required("Required!"),
  confNewPassSet: Yup.string()
    .oneOf([Yup.ref("newPassSet")], "Password's not match")
    .required("Required!"),
});
// Register //
export const INITIAL_VALUES_REG = {
  nameRegister: "",
  dateRegister: "",
  emailRegister: "",
  passRegister: "",
  confPassRegister: "",
  acceptTerms: false,
};

export const FORM_VALIDATION_REG = Yup.object({
  nameRegister: Yup.string()
    .min(2, "Mininum 2 characters")
    .max(15, "Maximum 15 characters")
    .required("Required!"),
  dateRegister: Yup.string().required("Required!"),
  emailRegister: Yup.string()
    .email("Invalid email format")
    .required("Required!"),
  passRegister: Yup.string()
    .min(8, "Minimum 8 characters")
    .required("Required!"),
  confPassRegister: Yup.string()
    .oneOf([Yup.ref("passRegister")], "Password's not match")
    .required("Required!"),
  acceptTerms: Yup.bool().oneOf([true], "Must Accept Terms and Conditions"),
});

// Edit Profile  //

export const INITIAL_VALUES_PROFILE_EDIT = {
  nameEdit: "",
  proffEdit: "",
  birthEdit: "",
  intCategoryEdit: "",
  sexEdit: "",
  ethnicityEdit: "",
  nationalityEdit: "",
  emailEdit: "",
  aboutEdit: "",
  careerEdit: "",
};

export const FORM_VALIDATION_PROFILE_EDIT = Yup.object({
  nameEdit: Yup.string()
    .min(2, "Mininum 2 characters")
    .max(15, "Maximum 15 characters")
    .required("Required!"),
  proffEdit: Yup.string(),
  birthEdit: Yup.string().required("Required!"),
  intCategoryEdit: Yup.string(),
  sexEdit: Yup.string(),
  ethnicityEdit: Yup.string(),
  nationalityEdit: Yup.string(),
  aboutEdit: Yup.string().max(250, "More than 250 chars"),
  careerEdit: Yup.string(),
});
