import React from "react";
import { Route, Redirect } from "react-router-dom";
// import { useSelector } from "react-redux";
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  // const auth =  useSelector((state) => state.auth);
  return (

    <Route
      {...rest}
      render={(props) =>
        restricted  ? <Redirect to="/profile" /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
