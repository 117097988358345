import React from "react";
import MMInput from "../common/mmInput/MMInput";
import { signUpPage } from "../../assets/data/data.json";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { register } from "../../store/slices/authSlice";
import { useHistory } from "react-router-dom";
import {
  FORM_VALIDATION_REG,
  INITIAL_VALUES_REG,
} from "../../utils/validations";
import MMRadio from "../common/mmRadio/MMRadio";

export default function RegisterForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmitForm = (values, { resetForm }) => {
    dispatch(register(values)).then(({ payload }) => {
      if (payload.status_code === 200) {
        resetForm();
        history.push("/sign-in");
      }
    });
  };

  return (
    <div className="register register-form-wrapper">
      <div className="heading-wrapper">
        <h1> {signUpPage.title} </h1>
      </div>
      <Formik
        initialValues={INITIAL_VALUES_REG}
        validationSchema={FORM_VALIDATION_REG}
        onSubmit={onSubmitForm}
      >
        <Form>
          <div className="register-form">
            <MMInput
              type="text"
              name="nameRegister"
              placeholder="Name"
              autoComplete="off"
            />
            <MMInput
              type="date"
              name="dateRegister"
              placeholder="Date of birth"
              autoComplete="off"
            />
            <MMInput
              type="email"
              name="emailRegister"
              placeholder="Email"
              autoComplete="off"
            />
            <MMInput
              type="password"
              name="passRegister"
              placeholder="Password"
              autoComplete="off"
            />
            <MMInput
              type="password"
              name="confPassRegister"
              placeholder="Repeat Password"
              autoComplete="off"
            />
          </div>
          <div className="register-actions">
            <div className="register-actions-top">
              <MMRadio name="acceptTerms" label={signUpPage.terms} />
            </div>
            <div className="register-actions-bottom">
              <div className="simple-btn-wrapper">
                <button type="submit"> {signUpPage.title} </button>
              </div>
              <div className="other-wrapper">
                <p>{signUpPage.otherText}</p>
                <Link to="/sign-in"> {signUpPage.linkToSignIn} </Link>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
