import React from "react";
import PropTypes from "prop-types";
import { icons } from "../../../assets/data/icons.json";

function MMVectorIcon({ name, big = false }) {
  return (
    <div className="vector-icon-wrapper" style={{ display: "flex" }}>
      {!big ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="icon-svg"
          dangerouslySetInnerHTML={{
            __html: `${icons[name]}`,
          }}
        ></svg>
      ) : (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="icon-svg"
          dangerouslySetInnerHTML={{
            __html: `${icons[name]}`,
          }}
        ></svg>
      )}
    </div>
  );
}

MMVectorIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default React.memo(MMVectorIcon);
