import React from "react";
import { EditProfileForm, LayoutOther } from "../../components";

export default function Profile() {
  return (
    <LayoutOther>
      <div className="main-content">
        <EditProfileForm />
      </div>
    </LayoutOther>
  );
}
