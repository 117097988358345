import React from "react";
import MMInput from "../common/mmInput/MMInput";
import { resetPasswordPage } from "../../assets/data/data.json";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { resetPass } from "../../store/slices/authSlice";

import {
  INITIAL_VALUES_RESET,
  FORM_VALIDATION_RESET,
} from "../../utils/validations";

export default function ResetPassForm() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const onSubmitForm = (values, { resetForm }) => {
    dispatch(resetPass(values));
    resetForm();
  };

  return (
    <>
      <div className="login login-form-wrapper">
        <div className="heading-wrapper">
          <h1> {resetPasswordPage.title} </h1>
        </div>
        <Formik
          initialValues={INITIAL_VALUES_RESET}
          validationSchema={FORM_VALIDATION_RESET}
          onSubmit={onSubmitForm}
        >
          <Form>
            <div className="login-form">
              <MMInput
                name="emailReset"
                type="email"
                autoComplete="off"
                placeholder="Email"
              />
            </div>
            <div className="login-actions">
              <div className="login-actions-bottom">
                <div className="simple-btn-wrapper">
                  <button type="submit"> {resetPasswordPage.submitBtn} </button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
        {auth && auth.errors && auth.errors.message && (
          <p className="err"> {auth.errors.message} </p>
        )}
        {auth && auth.errors && auth.errors.messageSuccess && (
          <p className="success"> {auth.errors.messageSuccess} </p>
        )}
      </div>
    </>
  );
}
