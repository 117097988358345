import React, { useEffect } from "react";
import "./MMInput.scss";
import { useField } from "formik";
import { useFormikContext } from "formik";
const MMInput = ({ name, initialVal, label, ...otherProps }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const configsInput = {
    ...field,
    ...otherProps,
    value: field.value || "",
  };

  useEffect(() => {
    initialVal && setFieldValue(name, initialVal);
  }, [initialVal, name, setFieldValue]);
  return (
    <div className="input-wrapper">
      {label && <label> {label} </label>}
      <input {...configsInput} />
      {meta && meta.error && meta.touched && (
        <p className="err">{meta.error}</p>
      )}
    </div>
  );
};

export default React.memo(MMInput);
