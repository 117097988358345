import React from "react";
import "./Header.scss";
import srcLogo from "../../assets/images/logo.png";
import MMVectorIcon from "../common/mmVectorIcon/MMVectorIcon";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";

export default function Header() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
    history.push("/");
  };

  return (
    <header className="header">
      <div className="header-logo">
        <img src={srcLogo} alt="Logo" />
      </div>
      <div className="header-actions">
        <nav>
          {auth && auth.user && auth.user.is_admin === "admin" && (
            <Link to="/create-voting" className="nav-link">
              <MMVectorIcon name="createVote" />
            </Link>
          )}

          <Link to="/voting" className="nav-link">
            <MMVectorIcon name="vote" />
          </Link>
          <Link to="/profile" className="nav-link">
            <MMVectorIcon name="user" />
          </Link>
          <div className="nav-link" onClick={handleLogout}>
            <MMVectorIcon name="logOut" />
          </div>
        </nav>
      </div>
    </header>
  );
}
