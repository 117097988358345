import { useState, useEffect, useCallback } from "react";
export const useToggle = (initialMode = false, ref) => {
  const [open, setOpen] = useState(initialMode);

  const handleClickDocument = useCallback(
    (e) => {
      if (ref.current && ref.current.contains(e.target.nextSibling)) return;

      setOpen(false);
    },
    [ref]
  );
  useEffect(() => {
    document.addEventListener("click", handleClickDocument);

    return () => {
      document.removeEventListener("click", handleClickDocument);
    };
  }, [handleClickDocument]);

  const toggle = useCallback((e) => {
    setOpen((prev) => !prev);
  }, []);

  return [open, toggle];
};
