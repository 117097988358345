import React from "react";
import "./MMModal.scss";

const MMModal = ({ children }) => {
  return (
    <div className="overlay-wrapper">
      <div className="modal">
        <div className="modal-header"></div>
        <div className="modal-body"> {children} </div>
      </div>
    </div>
  );
};

export default React.memo(MMModal);
