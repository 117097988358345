import React from "react";
import "./MMInputFile.scss";
import { useField } from "formik";

const MMInputFile = ({ name, changePreviewSrc, columnId, ...otherProps }) => {
  const [field] = useField(name);
  const handleChange = (e) => {
    readFile(e.currentTarget.files[0]);
  };

  const readFile = (file) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      if (columnId) {
        changePreviewSrc((prev) => {
          if (prev.hasOwnProperty(columnId)) {
            prev[columnId] = reader.result;
          }
          return {
            ...prev,
            [columnId]: reader.result,
          };
        });
      } else {
        changePreviewSrc(reader.result);
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const configsInput = {
    ...field,
    ...otherProps,
    onChange: handleChange,
    value: field.value || "",
  };
  return (
    <div className="input-wrapper">
      <input type="file" id={name} {...configsInput} />
    </div>
  );
};

export default React.memo(MMInputFile);
