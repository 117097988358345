import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../axiosConfig";
import { addNote } from "./noteSlice";

const initialState = {
  isLogin: false,
  token: null,
  user: null,
  loading: false,
};

// Delete Account //
export const deleteAcc = createAsyncThunk(
  "auth/deleteAcc",
  async (userId, { dispatch }) => {
    const response = await api.delete(`/profile/${userId}`);
    if (response.data.status_code === 200) {
      dispatch(logout());
    }
    return response.data;
  }
);

// Edit User Data //
export const editUserData = createAsyncThunk(
  "auth/editUserData",
  async (obj, { dispatch }) => {
    const response = await api.post("/edit-user", obj);
    await dispatch(getUserData());
    return response.data;
  }
);

// User Data //
export const getUserData = createAsyncThunk("auth/getUserData", async () => {
  const response = await api.get("/user");
  return response.data;
});

// Resset Password //
export const resetPass = createAsyncThunk(
  "auth/resetPass",
  async (obj, { dispatch }) => {
    const response = await api.post("/password/email", {
      email: obj.emailReset,
    });
    if (response.data.status_code === 400) {
      await dispatch(
        addNote({
          type: "error",
          message: response.data.message,
        })
      );
    } else {
      await dispatch(
        addNote({
          type: "success",
          message: response.data.message,
        })
      );
    }
    return response.data;
  }
);

// Set New Password //
export const setNewPass = createAsyncThunk(
  "auth/setNewPass",
  async (obj, { dispatch }) => {
    const response = await api.post("/password/reset", {
      email: obj.emailSet,
      token: obj.token,
      password: obj.newPassSet,
      password_confirmation: obj.confNewPassSet,
    });
    if (response.data.status_code === 400) {
      await dispatch(
        addNote({
          type: "error",
          message: response.data.message,
        })
      );
    } else {
      await dispatch(
        addNote({
          type: "success",
          message: response.data.message,
        })
      );
    }
    return response.data;
  }
);

// Login //
export const login = createAsyncThunk(
  "auth/login",
  async (obj, { dispatch }) => {
    const response = await api.post("/login", {
      email: obj.emailSignIn,
      password: obj.passSignIn,
    });

    if (response.data.status_code === 400) {
      dispatch(
        addNote({
          type: "error",
          message: response.data.message,
        })
      );
    } else {
      api.defaults.headers.Authorization = `Bearer ${response.data[0].token}`;
    }
    return response.data;
  }
);

// Registration //
export const register = createAsyncThunk(
  "auth/register",
  async (obj, { dispatch }) => {
    const response = await api.post("/register", {
      name: obj.nameRegister,
      date: obj.dateRegister,
      email: obj.emailRegister,
      password: obj.passRegister,
    });
    if (response.data.status_code === 400) {
      await dispatch(
        addNote({
          type: "error",
          message: response.data.message.email[0],
        })
      );
    } else {
      await dispatch(
        addNote({
          type: "success",
          message: response.data.message,
        })
      );
    }
    return response.data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    changeUserVotedStatus(state) {
      state.user.is_voted = true;
    },
    logout(state) {
      state.isLogin = false;
      state.token = null;
      state.user = null;
      state.loading = false;
      api.defaults.headers.Authorization = null;
    },
  },

  extraReducers: (builder) => {
    builder

      // Login //
      .addCase("auth/login/pending", (state) => {
        state.loading = true;
      })
      .addCase("auth/login/fulfilled", (state, { payload }) => {
        state.loading = false;
        if (payload.status_code === 200) {
          state.isLogin = true;
          state.token = payload[0].token;
        }
      })
      .addCase("auth/login/rejected", (state) => {
        state.loading = false;
      })

      // Registration //
      .addCase("auth/register/pending", (state) => {
        state.loading = true;
      })
      .addCase("auth/register/fulfilled", (state, { payload }) => {
        state.loading = false;
      })
      .addCase("auth/register/rejected", (state) => {
        state.loading = false;
      })
      // Resset Password //
      .addCase("auth/resetPass/pending", (state) => {
        state.loading = true;
      })
      .addCase("auth/resetPass/fulfilled", (state, { payload }) => {
        state.loading = false;
      })
      .addCase("auth/resetPass/rejected", (state) => {
        state.loading = false;
      })
      // Set New Password //
      .addCase("auth/setNewPass/pending", (state) => {
        state.loading = true;
      })
      .addCase("auth/setNewPass/fulfilled", (state, { payload }) => {
        state.loading = false;
      })
      .addCase("auth/setNewPass/rejected", (state) => {
        state.loading = false;
      })
      // User Data //
      .addCase("auth/getUserData/pending", (state) => {
        state.loading = true;
      })
      .addCase("auth/getUserData/fulfilled", (state, { payload }) => {
        state.loading = false;
        state.user = payload.user;
      })
      .addCase("auth/getUserData/rejected", (state) => {
        state.loading = false;
      })
      // Edit User Data //
      .addCase("auth/editUserData/pending", (state) => {
        state.loading = true;
      })
      .addCase("auth/editUserData/fulfilled", (state, { payload }) => {
        state.loading = false;
      })
      .addCase("auth/editUserData/rejected", (state) => {
        state.loading = false;
      })
      // Edit User Data //
      .addCase("auth/deleteAcc/pending", (state) => {
        state.loading = true;
      })
      .addCase("auth/deleteAcc/fulfilled", (state, { payload }) => {
        state.loading = false;
      })
      .addCase("auth/deleteAcc/rejected", (state) => {
        state.loading = false;
      });
  },
});

export const { logout, changeUserVotedStatus } = authSlice.actions;

export default authSlice.reducer;
