import React from "react";
import { Layout, ResetPassForm } from "../../components";

export default function ResetPassword() {
  return (
    <Layout>
      <ResetPassForm />
    </Layout>
  );
}
