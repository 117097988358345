import React from "react";
import "./MMRadio.scss";
import { useField, useFormikContext } from "formik";
import MMVectorIcon from "../mmVectorIcon/MMVectorIcon";

const MMRadio = ({ name, label, ...otherProps }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    setFieldValue(name, e.target.checked);
  };

  const configsRadio = {
    type: "checkbox",
    id: name,
    onChange: handleChange,
    checked: field.value,
  };

  return (
    <div className="radio-wrapper">
      <label htmlFor={name}>
        <input {...configsRadio} />
        <span className="terms">{label}</span>
        <span className="checkmark"></span>
        {meta && meta.error && meta.touched && <MMVectorIcon name="invalid" />}
      </label>
    </div>
  );
};

export default React.memo(MMRadio);
